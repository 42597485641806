import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import MyLink from './../components/Link/index';

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Page Not found" />

    <div style={STYLES}>
      <h1>404: Page Not Found</h1>
      <nav>
        <MyLink url="/" activeClassName="active" text="Go to Home Page" />
      </nav>
    </div>
  </Layout>
)

const STYLES = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center'
}
export default NotFoundPage
